import { Plot, Status } from "@/types";

import { LikeButton, Placeholder, StatusLabel } from "@/components";
import { isAvailable, price as priceFormatter } from "@/services";
import {
  faChair,
  faCompass,
  faVectorSquare,
} from "@awesome.me/kit-b9851c3d09/icons/classic/regular";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useParams } from "react-router-dom";
import { formatName } from "@/services/Plot";

type SmallCardProps = {
  plot: Plot;
  status: Status;
  className?: string;
  children?: React.ReactNode;
};

export default function Small({
  plot,
  status,
  className,
  children,
}: Readonly<SmallCardProps>) {
  const { projectSlug, phaseSlug } = useParams();
  const {
    name,
    project_type,
    price,
    room_count,
    attachments,
    sun_position_outdoor,
    living_surface,
  } = plot;
  return (
    // Note: We HAVE to use grid here because the content determines flex column width, leading to a non-uniform grid
    <div className={`${className} relative`}>
      <LikeButton
        plot={plot}
        hasOverlay={true}
        className="absolute top-2.5 left-2.5"
      />

      <Link
        to={`/projects/${projectSlug}/phases/${phaseSlug}/plots/${plot.id}`}
        className="grid grid-cols-card gap-x-2 lg:gap-x-0"
      >
        <figure className="relative col-start-1 overflow-hidden md:col-end-2">
          <span className="absolute object-cover w-full h-full">
            {attachments.length > 0 && attachments[0]?.original ? (
              <img
                src={attachments[0].original}
                srcSet={attachments[0].srcset}
                sizes="(min-width: 1024px) 150px, 32dvw"
                alt={name}
                width="150"
                height="84"
                className="object-cover object-center w-full h-full"
                loading="lazy"
              />
            ) : (
              <Placeholder className="object-cover object-center w-full h-full" />
            )}
          </span>
        </figure>

        <div className="flex flex-col col-start-2 p-4 col-span-full gap-y-2 lg:gap-y-6 shrink-0 grow-0 md:col-end-3">
          {/* Title */}
          <div className="flex flex-col gap-y-1">
            <div className="flex flex-col flex-wrap justify-between sm:gap-x-2 sm:flex-row">
              <h3 className="font-semibold h4">{formatName(plot)}</h3>

              <StatusLabel
                status={status}
                className="!flex-row sm:flex-row-reverse"
              />
            </div>

            <span className="font-medium small">
              {isAvailable(status) ? priceFormatter(plot) : status.name}
            </span>
          </div>

          {/* Icons */}
          <div className="flex justify-between text-base select-none gap-x-4 lg:gap-8">
            <span className="flex items-center gap-2" title="Woonoppervlakte">
              <span className="text-lg">
                <FontAwesomeIcon
                  icon={faVectorSquare}
                  className="text-primary"
                />
              </span>
              <span className="text-sm break-all text-nowrap line-clamp-1">
                {living_surface} m<sup>2</sup>
              </span>
            </span>

            <span className="flex items-center gap-2" title="Aantal kamers">
              <span className="text-lg">
                <FontAwesomeIcon icon={faChair} className="text-primary" />
              </span>
              <span className="text-sm break-all text-nowrap line-clamp-1">
                {room_count}{" "}
              </span>
            </span>

            {sun_position_outdoor && (
              <span className="flex items-center gap-2" title="Zonligging">
                <span className="text-lg">
                  <FontAwesomeIcon icon={faCompass} className="text-primary" />
                </span>
                <span
                  className="text-sm break-all line-clamp-1"
                  title={sun_position_outdoor}
                >
                  {sun_position_outdoor}
                </span>
              </span>
            )}
          </div>
        </div>
      </Link>

      {children}
    </div>
  );
}
