import {
  FavoritesNotificationBar,
  useNotification,
  WishlistNotificationBar,
} from "@/components";
import { PhaseQuery } from "@/loaders";
import { useSuspenseQuery } from "@tanstack/react-query";
import { reverseAPCA, sRGBtoY } from "apca-w3";
import { colorParsley } from "colorparsley"; // optional string parsing
import invert from "invert-color";

import { Outlet, useParams } from "react-router-dom";
import {Project} from "@/types";

export default function ProjectRoot({ children, className, ...rest }: any) {
  const params = useParams();
  const projectSlug = params.projectSlug!;
  const phaseSlug = params.phaseSlug!;

  const {
    data: { phase, statuses, plots, project },
  } = useSuspenseQuery(PhaseQuery(projectSlug, phaseSlug));

  const { showNotification, hideNotification } = useNotification();

  const primaryColor = sRGBtoY(colorParsley(phase["primary-color"]));
  const primaryContrast = invert(reverseAPCA(9, primaryColor), true);

  const secondaryColor = sRGBtoY(colorParsley(phase["secondary-color"]));
  const secondaryContrast = invert(reverseAPCA(-9, secondaryColor), true);

  return (
    <div {...rest} className={className}>
      {phase && (
        <style type="text/css">{`
            :host,
            #dom-wrapper {
              --primary: ${phase["primary-color"]};
              --secondary: ${phase["secondary-color"]};
              --dark: ${phase["dark-color"]};
              --light: ${phase["light-color"]};
              --contrast_primary: ${primaryContrast};
              --contrast_secondary: ${secondaryContrast};
            }
          `}</style>
      )}

      <WishlistNotificationBar plots={plots} statuses={statuses} projectType={project.type} />
      {showNotification && (
        <FavoritesNotificationBar onClose={hideNotification} />
      )}

      <Outlet />
    </div>
  );
}
